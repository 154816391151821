import React, { useEffect, useState } from "react";

const BackToTop = () => {
  let [showBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 160) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  return (
    <>
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
        id={showBtn ? "back" : ""}
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};

export default BackToTop;
