import React from 'react';
import tab1 from "../../assets/img/tech.jpg"
import tab2 from "../../assets/img/nonTech.jpg"
import tab3 from "../../assets/img/valueAd.jpg"
import tab4 from "../../assets/img/specialized.jpg"
import "./course.css"
import TrnModules from '../table/trainingmodule/TrnModules';


const Courses = () => {
  return (
    <>
      <section id="tabs" className="tabs">
        <div className="container" data-aos="fade-up">
          <h2>Courses</h2>
          <article className="coursesFor">
            <aside>
              <h3>Who this course is for?</h3>
              <ul>
                <li>
                  <i className="bi bi-check2-all"></i>
                  This package is designed for any undergraduates or job seekers
                  to enhance their skill sets in their career path by offering
                  them courses in the technical and non technical aspects as per
                  industry demands.
                </li>
                <li>
                  <i className="bi bi-check2-all"></i>
                  Any non- engineering graduates <b>(BA /BCom /BBA /BBM /MCom /MBA /Diploma-any stream)</b>.
                </li>
                <li>
                  <i className="bi bi-check2-all"></i> Freshers/Jobseekers
                </li>
              </ul>
            </aside>
            <aside>
              <h3>Why take this course?</h3>
              <ul>
                <li>
                  <i className="bi bi-check2-all"></i>
                  This course is blended with current industry requirements in
                  various fields where even a fresher can start working on
                  projects directly.{" "}
                </li>
                <li>
                  <i className="bi bi-check2-all"></i>
                  This package gives desired knowledge on various job roles and
                  builds confidence in freshers to immediately transform in to
                  an employed class after completing the course with us.
                </li>
                <li>
                  <i className="bi bi-check2-all"></i>
                  Your performance will increase by activities like
                  Presentations, Assignments, Mock Interviews & Mock tests which
                  will run regularly.{" "}
                </li>
                <li>
                  <i className="bi bi-check2-all"></i>
                  100% JOB ASSISTANCE after completion of course to make your
                  Profile reach to Hundreds of Recruiters in our network.
                </li>
              </ul>
            </aside>
          </article>
          <TrnModules />
         
          {/* <h2>Training Modules</h2>
          <ul className="nav nav-tabs row d-flex">
            <li className="nav-item col-3">
              <a
                className="nav-link active show"
                data-bs-toggle="tab"
                data-bs-target="#tab-1"
              >
                <i className="fa-solid fa-database"></i>
                <h4 className="d-none d-lg-block">Technical</h4>
              </a>
            </li>
            <li className="nav-item col-3">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-2"
              >
                <i className="fa-solid fa-pen-nib"></i>
                <h4 className="d-none d-lg-block">Non Technical</h4>
              </a>
            </li>
            <li className="nav-item col-3">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-3"
              >
                <i className="fa-solid fa-feather-pointed"></i>
                <h4 className="d-none d-lg-block">Value Adds</h4>
              </a>
            </li>
            <li className="nav-item col-3">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab-4"
              >
                <i className="ri-store-line"></i>
                <h4 className="d-none d-lg-block">Specialized Courses</h4>
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div className="tab-pane active show" id="tab-1">
              <div className="row">
                <br />
                <br />
                <div
                  className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h3>Technical</h3>
                  <p>The Following are the Technical Modules Covered</p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> SQL
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Hardware
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Networking
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Mail Servers
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Excel, Word,PPT
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> EOS Windows-Linux
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-6 order-1 order-lg-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img src={tab1} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-2">
              <div className="row">
                <br />
                <br />
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>Non-Technical</h3>
                  <p>The Following are the Non-Technical Modules Covered</p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Spoken English
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Email Etiquette
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Behavioral
                      Grooming
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Personality
                      Development
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img src={tab2} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-3">
              <div className="row">
                <br />
                <br />
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>Value Adds</h3>
                  <p>
                    These are the Additional & Much Essentials things Covered in
                    this Course
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Grooming
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Mock Interviews
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Assignment & Test
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Presentation
                      Activities
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img src={tab3} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-4">
              <div className="row">
                <br />
                <br />
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>Specialized Courses</h3>
                  <p>
                    Apart from the above mentioned course you can also enroll
                    for specialization courses
                  </p>
                  <p className="fst-italic">
                    Not Included in the Course (Can be Registered Separately)
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Process Analyst
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Digital Marketing
                      Analyst
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Cloud Analyst
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> HR & Recruitment
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img src={tab4} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default Courses