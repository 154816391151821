import React from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container d-md-flex py-4 justify-content-between">
        <div className="copyright">
          <span style={{ fontSize: "x-large" }}>
            <b style={{ color: "#f86b1f", fontWeight: "900" }}>Pro</b>Spiders
          </span>
          <br />
        </div>
        <div>
          &copy; Copyright <strong></strong>. All Rights Reserved 2022
        </div>
      </div>
    </footer>
  );
};

export default Footer;
