import React from "react";
import { useRoutes } from "react-router-dom";
import LandingPage from "../components/landingPage/LandingPage";
import PageNotFound from "../components/PageNotFound";
import BranchesList from "../components/branches/BranchesList";
import OnlineBatchList from "../components/batches/OnlineBatchList";
import OfflineBatchList from "../components/batches/OfflineBatchList";
// import AllBatches from "../components/batches/AllBatches";
import AllBatchesMain from "../components/batches/AllBatchesMain";
import ModeOfflineBatchList from "../components/batches/OfflineBatchList";
import AllBranchList from "../components/branches/AllBranchList";


const PublicRoutes = () => {
  let publicRoute = useRoutes([
    {
      path: "/",
      element: <LandingPage />,
    },
  
    {
      path: "/onlineBatchList",
      element: <OnlineBatchList />,
    },
    {
      path: "/offlineBatchList",
      element: <OfflineBatchList />,
    },
    // {
    //   path: "/allBranchList",
    //   element: <BranchesList />,
    // },
    {
      path: "/allBranchList",
      element: <AllBranchList/>,
    },
    {
      path: "/allBatches",
      element: <AllBatchesMain/>
    },
    // {
    //   path: "/allBatches",
    //   element: <ModeOfflineBatchList/>
    // },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);
  return publicRoute;
};

export default PublicRoutes;
