import React from "react";

const Banner = () => {
  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-delay="100">
          <div className="row">
            <div className="col-xl-6">
              <h1>
                India's First Organization as finishing School for Professional
                Courses
              </h1>
              <h2>"Training Your Way to Success"</h2>
              <h5
                style={{
                  color: "#fff",
                  marginTop: "15px",
                  fontSize: "medium",
                  textTransform: "full-size-kana",
                  fontWeight: "900",
                }}
              >
                For non- engineering graduates <br />(
                BA/BCom/BBA/BBM/MCom/MBA/Diploma-any stream )
              </h5>
              <a href="#contact" className="btn-get-started scrollto">
                Register Now
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
