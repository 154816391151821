import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";

const App = () => {
  return (
    <Fragment>
      <Router>
        <ToastContainer theme="dark" />
       <PublicRoutes></PublicRoutes>
      </Router>
    </Fragment>
  );
};

export default App;
