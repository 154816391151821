import React from "react";
import file from "../assets/img/pnf.jpeg";
import { NavLink } from "react-router-dom";

const PageNotFound = () => {
  return (
    <section
      style={{
        height: "90vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img src={file} alt="" style={{ height: "700px" }} />
      <NavLink
        to="/"
        style={{
          textDecoration: "none",
          color: "#FF4D4D",
          background: "#EFEFEF",
          padding: "4px 8px",
          borderRadius: "6px",
        }}
      >
        Back to Home
      </NavLink>
    </section>
  );
};

export default PageNotFound;
