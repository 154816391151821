import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import OnlineBatchList from "./OnlineBatchList";
import OfflineBatchList from "./OfflineBatchList";
import OnlineNavbar from "../navbar/OnlineNavbar";
import Enquiry from "../enquiry/Enquiry";
import { Typography } from "@mui/material";
import "./onlinebatch.css";
import BatchesAllList from './BatchesAllList';
import BackToTop from "../bTop/BackToTop";

export default function AllBatchesMain() {
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
    <OnlineNavbar/>
    <Box sx={{ width: "100%",display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center",marginTop:"6%"}} className="tabContainer">
      <Typography variant="h4" sx={{textTransform:"uppercase",fontWeight:"bold",textAlign:"center"}}>
         <div className="container"> BATCHES</div>
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
          },
        }}
        textColor="#495579"
        indicatorColor="primary"
          aria-label="secondary tabs example"
          className="subTab"
      >
            <Tab
          value="one"
          label="All Batches"
          disableRipple
          sx={{
            borderBottom: value === "one" ? "5px solid #DDE6ED" : "#FFF",
            fontWeight: "bold",
            marginTop:"15px",
            backgroundColor: value === "one" ? "#FF8400" :""
          }}
        />
        <Tab
          value="two"
          disableRipple
          label="Online Batches"
          sx={{
            borderBottom: value === "two" ? "5px solid  #DDE6ED" : "#FFF",
            fontWeight: "bold",
           marginTop:"15px",
           backgroundColor: value === "two" ? "#FF8400" :""
          }}
        />
        <Tab
          value="three"
          label="Offline Batches"
          disableRipple
          sx={{
            borderBottom: value === "three" ? "5px solid #DDE6ED" : "#FFF",
            fontWeight: "bold",
            marginTop:"15px",
            backgroundColor: value === "three" ? "#FF8400" :""
          }}
        />
    
      </Tabs>
      {/* conditions */}
      {/* {value == "one" ?<OnlineBatchList/>  :<OfflineBatchList/> } */}

      {value === "one" ?<BatchesAllList/>  : value === "two" ? <OnlineBatchList /> : <OfflineBatchList />}
    </Box>
   <div style={{marginTop:"-6%"}}> <Enquiry/></div>
   <div><BackToTop/></div>
    </Box>
  );
}
