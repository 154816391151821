import React, { useContext, useEffect, useState } from "react";
import "./onlinebatch.css";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Autocomplete, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AxiosInstance from "../../api/AxiosInstance";
import { Grid, TextField } from "@mui/material";
import { PostContext } from "../ContextApi";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Pagination from "@mui/material/Pagination";

const OfflineBatchList = () => {
  let [offLineBatch, setOffLineBatch] = useState([]);
  const [current_page, setCurrent_page] = useState(1);
  const [per_page, setPer_page] = useState(25);
  const { enquireData, setEnquireData } = useContext(PostContext);

  //! Search Data
  let searchData = async (e) => {
    if (e.target.value === "") {
      setSearchTerm("a");
    } else {
      setSearchTerm(e.target.value);
    }
  };

  //! Search Filter

  const [branchOption, setBranchOption] = useState([]);
  const [courseOption, setCourseOption] = useState([]);
  const [sessionOption, setSessionOption] = useState([]);
  const [branchList, setBranchList] = useState(null);
  const [courseList, setCourseList] = useState(null);
  const [sessionList, setSessionList] = useState(null);
  const [error, setError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("a");

  const [date, setDate] = useState("");

  //============Get Branches================================

  useEffect(() => {
    let fetchData = async () => {
      const bdata = await AxiosInstance.get(`spiders/branch/`);
      let branchData1 = bdata.data.results;
      setBranchOption(branchData1);
    };
    fetchData();
  }, []);

  //============Get Courses================================

  useEffect(() => {
    let fetchData = async () => {
      const cdata = await AxiosInstance.get(`spiders/course/`);
      let courseData1 = cdata.data.results;
      setCourseOption(courseData1);
    };
    fetchData();
  }, []);

  //============Get Sessions================================

  useEffect(() => {
    let fetchData = async () => {
      const sdata = await AxiosInstance.get(`commons/session/`);
      let sessionData1 = sdata.data.results;
      setSessionOption(sessionData1);
    };
    fetchData();
  }, []);

  //Filters

  let dateFormatter = date.split("-");
  let branchList_1 = branchList === null ? "" : branchList.id;
  let courseList_1 = courseList === null ? "" : courseList.id;
  let sessionList_1 = sessionList === null ? "" : sessionList.id;
  let reverseDate = `${dateFormatter[2]}-${dateFormatter[1]}-${dateFormatter[0]}`;

  const params = {
    branch_id: branchList_1,
    course_id: courseList_1,
    date: date != "" ? reverseDate : "",
    session_id: sessionList_1,
  };

  //pagination
  let handleChangePage = (event, value) => {
    setCurrent_page(value);
  };

  // Reset Filters
  const resetFilters = async (e) => {
    e.preventDefault();
    setBranchList(null);
    setCourseList(null);
    setSessionList(null);
    setDate("");
    setQueryString("");
  };

  let [queryString, setQueryString] = useState("");

  let generateQueryString = () => {
    const nonEmptyKeys = Object.keys(params).filter(
      (key) => params[key] !== ""
    );
    setQueryString(
      nonEmptyKeys.map((key) => `${key}=${params[key]}`).join("&")
    );
  };

  //  to frtch online classes (mode of class 1)
  useEffect(() => {
    let fetchData = async () => {
      let fetchOnline = await AxiosInstance.get(
        `spiders/classes-list/?${queryString}&modeofclass_id=${2}`
      );
      let finalData = fetchOnline.data.results;
      setOffLineBatch(finalData);
    };
    fetchData();
  }, [queryString]);

  return (
    <>
      <section id="tabs" className="tabs" style={{ width: "80%" }}>
        <div
          className="container"
          data-aos="fade-up"
          style={{ marginTop: "-5%" }}
        >
          {/* <h2>Batches</h2> */}

          {/* ============================= */}

          <Grid
            container
            style={{ marginTop: "3%", gap: "10px", marginLeft: "1%" }}
            className="subContainer"
          >
            <Grid item md={2.2} onChange={searchData}>
              <Autocomplete
                options={branchOption}
                value={branchList}
                name="state_name"
                onChange={(event, newColgdis) => {
                  setBranchList(newColgdis);
                }}
                getOptionLabel={(option) => option.branch}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Branches"
                    variant="outlined"
                    error={error && !branchList}
                    helperText={
                      error && !branchList ? "Please select Branchlist" : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={2.2} onChange={searchData}>
              <Autocomplete
                options={courseOption}
                value={courseList}
                name="state_name"
                onChange={(event, newColgdis) => {
                  setCourseList(newColgdis);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Courses"
                    variant="outlined"
                    error={error && !courseList}
                    helperText={
                      error && !courseList ? "Please select courseList" : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8} onChange={searchData}>
              <Autocomplete
                options={sessionOption}
                value={sessionList}
                name="course_list"
                onChange={(event, newColgdis) => {
                  setSessionList(newColgdis);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="WeekDays / WeekEnd"
                    variant="outlined"
                    error={error && !sessionList}
                    helperText={
                      error && !sessionList ? "Please select Sessions" : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={1.5}>
              <TextField
                type="date"
                fullWidth
                id="Date"
                label="Date "
                name="Date"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                autoFocus
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </Grid>

            <Grid item md={1.8}>
              <button
                type="button"
                style={{
                  fontSize: "20px",
                  color: "#F2E7D5",
                  padding: "4px 30px",
                  cursor: "pointer",
                  backgroundColor: "#2C3333",
                  borderRadius: "40px",
                  border: "2px solid #efefef",
                  marginTop: "6px",
                }}
                onClick={generateQueryString}
                className="searchField"
              >
                Search <SearchIcon />
              </button>
            </Grid>
            <Grid item md={1.8}>
              <button
                type="button"
                style={{
                  fontSize: "20px",
                  color: "#fff",
                  padding: "4px 30px",
                  cursor: "pointer",
                  backgroundColor: "#3C486B",
                  borderRadius: "40px",
                  border: "none",
                  marginTop: "6px",
                }}
                className="searchField"
                onClick={resetFilters}
              >
                Reset All <FilterAltIcon />
              </button>
            </Grid>
          </Grid>

          <article
            style={{
              width: "100%",
              padding: "10px",
              fontFamily: "'Poppins', sans-serif",
            }}
            className="cardsTab"
          >
            <aside className="coursesForr">
              <Card
                sx={{
                  maxWidth: 345,
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 345,
                  minHeight: 305,
                  borderLeft: "5px solid #DDE6ED",
                  borderRadius: "15px 50px",
                }}
                className="boxTab"
              >
                <Box sx={{ flexBasis: "20%" }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{ backgroundColor: "#F97B22" }}
                        aria-label="recipe"
                      >
                        <BusinessOutlinedIcon />
                      </Avatar>
                    }
                    title={
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "600",
                          fontSize: "21px",
                          fontStyle: "normal",
                          color: "#F97B22",
                        }}
                      >
                        Any
                      </Typography>
                    }
                  />
                </Box>

                <Box sx={{ flexBasis: "30%" }}>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: "20px", fontWeight: "600" }}
                    >
                      <span style={{ fontWeight: "600" }}>Courses :</span>{" "}
                      <span style={{ fontStyle: "italic", color: "#27374D" }}>
                        Any
                      </span>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        marginTop: "15px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Subject :</span>{" "}
                      <span style={{ fontStyle: "italic", color: "#27374D" }}>
                        Any
                      </span>
                    </Typography>
                  </CardContent>
                </Box>
                <Box sx={{ flexBasis: "10%" }}>
                  <CardActions disableSpacing>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CalendarMonthIcon style={{ color: "#F97B22" }} />{" "}
                        &nbsp;
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "600", fontSize: "17px" }}
                        >
                          {" "}
                          Any
                        </Typography>
                      </div>
                    </Box>
                  </CardActions>
                </Box>

                <Box sx={{ flexBasis: "10%" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    &nbsp;&nbsp; <MenuBookIcon style={{ color: "#F97B22" }} />{" "}
                    &nbsp;
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      sx={{ fontWeight: "600", fontSize: "17px" }}
                    >
                      Sessions : &nbsp;
                      <span
                        style={{
                          textTransform: "capitalize",
                          color: "#27374D",
                        }}
                      >
                        Any
                      </span>
                    </Typography>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginRight: "15px",
                    flexBasis: "10%",
                  }}
                >
                  <a href="#regForm">
                    <Button
                      variant="contained"
                      sx={{
                        background: "#F97B22",
                        "&:hover": { background: "#2C3333" },
                      }}
                    >
                      Register
                    </Button>
                  </a>
                </Box>
              </Card>
              {
                <>
                  {" "}
                  {offLineBatch
                    .slice(
                      (current_page - 1) * per_page,
                      (current_page - 1) * 25 + 25
                    )
                    .map((data) => (
                      <Card
                        sx={{
                          maxWidth: 345,
                          display: "flex",
                          flexDirection: "column",
                          minWidth: 345,
                          minHeight: 305,
                          borderLeft: "5px solid #DDE6ED",
                          borderRadius: "15px 50px",
                        }}
                        className="boxTab"
                      >
                        <Box sx={{ flexBasis: "20%" }}>
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{ backgroundColor: "#F97B22" }}
                                aria-label="recipe"
                              >
                                <BusinessOutlinedIcon />
                              </Avatar>
                            }
                            title={
                              <Typography
                                variant="h5"
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "21px",
                                  fontStyle: "normal",
                                  color: "#F97B22",
                                }}
                              >
                                {" "}
                                {data.branch.branch}
                              </Typography>
                            }
                          />
                        </Box>

                        <Box sx={{ flexBasis: "30%" }}>
                          <CardContent>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontSize: "20px", fontWeight: "600" }}
                            >
                              <span style={{ fontWeight: "600" }}>
                                Courses :
                              </span>{" "}
                              <span
                                style={{
                                  fontStyle: "italic",
                                  color: "#27374D",
                                }}
                              >
                                {data.course.name}
                              </span>
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                marginTop: "15px",
                                fontSize: "20px",
                                fontWeight: "600",
                              }}
                            >
                              <span style={{ fontWeight: "600" }}>
                                Subject :
                              </span>{" "}
                              <span
                                style={{
                                  fontStyle: "italic",
                                  color: "#27374D",
                                }}
                              >
                                {data.subject}
                              </span>
                            </Typography>
                          </CardContent>
                        </Box>
                        <Box sx={{ flexBasis: "10%" }}>
                          <CardActions disableSpacing>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              width="100%"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CalendarMonthIcon
                                  style={{ color: "#F97B22" }}
                                />{" "}
                                &nbsp;
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "600", fontSize: "17px" }}
                                >
                                  {" "}
                                  {data.date} , {data.time}
                                </Typography>
                              </div>
                            </Box>
                          </CardActions>
                        </Box>

                        <Box sx={{ flexBasis: "10%" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            &nbsp;&nbsp;{" "}
                            <MenuBookIcon style={{ color: "#F97B22" }} /> &nbsp;
                            <Typography
                              variant="h6"
                              color="text.secondary"
                              sx={{ fontWeight: "600", fontSize: "17px" }}
                            >
                              Sessions : &nbsp;
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  color: "#27374D",
                                }}
                              >
                                {data.session.name}
                              </span>
                            </Typography>
                          </div>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            marginRight: "15px",
                            flexBasis: "10%",
                          }}
                        >
                          <a href="#regForm">
                            <Button
                              variant="contained"
                              sx={{
                                background: "#F97B22",
                                "&:hover": { background: "#2C3333" },
                              }}
                              onClick={() => {
                                setEnquireData(data);
                              }}
                            >
                              Register
                            </Button>
                          </a>
                        </Box>
                      </Card>
                    ))}
                </>
              }
            </aside>
          </article>
        </div>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "40%" }}
          count={Math.ceil(offLineBatch.length / 25)}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          defaultPage={1}
          siblingCount={1}
          page={current_page}
          onChange={handleChangePage}
          className="paginationBlock"
        />
      </section>
    </>
  );
};

export default OfflineBatchList;
