import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import Axios from "../../api/AxiosInstance";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: "#F8931F",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    color: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Batches = () => {
  let [onlineData, setOnlineData] = useState([]);
  let [offlineData, setOfflineData] = useState([]);

  useEffect(() => {
    let fetchOnline = async () => {
      const OnlineDataRes = await Axios.get(
        `spiders/classes-list/?modeofclass_id=1`
      );
      let finalData = OnlineDataRes.data.results.slice(0, 100);
      setOnlineData(finalData);
    };
    fetchOnline();
  }, []);

  useEffect(() => {
    let fetchOffline = async () => {
      const OfflineDataRes = await Axios.get(
        `spiders/classes-list/?modeofclass_id=2`
      );
      let finalOfflineData = OfflineDataRes.data.results.slice(0, 100);
      setOfflineData(finalOfflineData);
    };
    fetchOffline();
  }, []);

  const infoBoxStyle = {
    overflow: "auto",
    width: "",
  };

  if (window.innerWidth <= 908) {
    infoBoxStyle.overflowY = "auto";
    infoBoxStyle.width = "100%";
  }

  return (
    <>
      <section id="i" className="services section-bg ">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Batches</h2>
            <p>
              Students Can Register for both online or offline Classes.. Here
              are the Dates and timing of Commencing of Batches
            </p>
          </div>
          <div className="row rowX">
            <div className="col-md-6" style={infoBoxStyle}>
              <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                <i className="bi bi-display"></i>
                <h4>
                  <span>Online</span>
                </h4>
                <p>
                  {/* Schedule for Online Classes <b>On Every Monday</b> */}
                  &nbsp;
                </p>
                <aside>
                  <div className="table-container" style={{ height: "auto" }}>
                    <Table className="batchSchedule" sx={{ border: "none" }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            sx={{ fontWeight: "600", width: "20%" }}
                          >
                            Branch
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ fontWeight: "500", width: "20%" }}
                          >
                            Course
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ fontWeight: "500", width: "20%" }}
                          >
                            Subject
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ fontWeight: "500", width: "20%" }}
                          >
                            Date and Time
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            sx={{ fontWeight: "500", width: "20%" }}
                          >
                            Sessions
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Any
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Any
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Any
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Any
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Fastrack{" "}
                            <a href="#regForm">
                              <Button
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  color: "#F8931F",
                                  border: "1px solid #F8931F",
                                }}
                                variant="outlined"
                              >
                                {" "}
                                Enroll Now
                              </Button>
                            </a>{" "}
                          </StyledTableCell>
                        </TableRow>
                        {onlineData.map(ele => {
                          return (
                            <Fragment key={ele.id}>
                              <StyledTableRow className="batchSchedule">
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ color: "white" }}
                                >
                                  {ele.branch.branch === ""
                                    ? "N/A"
                                    : ele.branch.branch}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{
                                    color: "white",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {ele.course.name === ""
                                    ? "N/A"
                                    : ele.course.name}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{
                                    color: "white",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {ele.subject === null ? "N/A" : ele.subject}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  style={{ color: "white" }}
                                >
                                  {ele.date === "" ? "N/A" : ele.date}{" "}
                                  {ele.time === "" ? "N/A" : ele.time}
                                </StyledTableCell>

                                <StyledTableCell
                                  align="center"
                                  style={{
                                    color: "white",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {ele.session.name === ""
                                    ? "N/A"
                                    : ele.session.name}
                                </StyledTableCell>
                              </StyledTableRow>
                            </Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                </aside>
              </div>
            </div>
            <div className="col-md-6 mt-4 mt-md-0" style={infoBoxStyle}>
              <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                <i className="bi bi-book"></i>
                <h4>
                  <span>Offline</span>
                </h4>
                <p>
                  {/* Schedule for Offline Classes <b>On Every Thursday</b> */}
                  &nbsp;
                </p>
                <aside>
                  <div className="table-container" style={{ height: "auto" }}>
                    <Table className="batchSchedule" sx={{ border: "none" }}>
                      <TableHead>
                        <TableRow id="tableRow">
                          <StyledTableCell style={{ width: "20%" }}>
                            Branch
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: "20%" }}
                            align="center"
                          >
                            Course
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: "20%" }}
                            align="center"
                          >
                            Subject
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: "20%" }}
                            align="center"
                          >
                            Date and Time
                          </StyledTableCell>
                          <StyledTableCell
                            style={{ width: "20%" }}
                            align="center"
                          >
                            Sessions
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Any
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Any
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Any
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Any
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Fastrack{" "}
                            <a href="#regForm">
                              <Button
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  color: "#F8931F",
                                  border: "1px solid #F8931F",
                                }}
                                variant="outlined"
                              >
                                Enroll Now
                              </Button>
                            </a>
                          </StyledTableCell>
                        </TableRow>
                        {offlineData.length < 1
                          ? null
                          : offlineData.map(ele => {
                              return (
                                <Fragment key={ele.id}>
                                  <StyledTableRow className="batchSchedule">
                                    <StyledTableCell
                                      scope="row"
                                      style={{ color: "white" }}
                                    >
                                      {ele.branch.branch === ""
                                        ? "N/A"
                                        : ele.branch.branch}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      style={{
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {ele.course.name === ""
                                        ? "N/A"
                                        : ele.course.name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      style={{
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {ele.subject === null
                                        ? "N/A"
                                        : ele.subject}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      style={{ color: "white" }}
                                    >
                                      {ele.date === "" ? "N/A" : ele.date}{" "}
                                      {ele.time === "" ? "N/A" : ele.time}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="center"
                                      style={{
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {ele.session.name === ""
                                        ? "N/A"
                                        : ele.session.name}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </Fragment>
                              );
                            })}
                      </TableBody>
                    </Table>
                  </div>
                </aside>
              </div>
              {/* button for view batches */}
              {onlineData.length > 100 || offlineData.length > 100 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "125px",
                  }}
                >
                  <Link
                    to="/allBatches"
                  >
                    View More Batches
                  </Link>
                </Box>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Batches;
