import React from 'react'
import Contact from './Contact'
import Form from './Form'

const Enquiry = () => {
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Interested? Register Now</h2>
          <p className="contactTittle">
            Please fill your details in the below Form our team will reach you.
            <span>
              Any Queries?? REACH US on the contacts shared below |or| Reach us
              on the Location given below. we will be glad to assist you.
            </span>
          </p>
        </div>
        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <Contact />
          <Form />
        </div>
      </div>
    </section>
  );
}

export default Enquiry