import React, { useState, useEffect } from "react";
import "./branch.css";
import AxiosInstance from "../../api/AxiosInstance";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Tooltip from "@mui/material/Tooltip";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BranchesList = () => {
  // state values
  const [allBranches, SetAllBranches] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const branchList = await AxiosInstance.get(`spiders/branch/`);
      let finalBranchList = branchList.data.results;
      SetAllBranches(finalBranchList);
    };
    fetchData();
  }, []);


  return (
    <>
      <section id="tabs" className="tabs" style={{marginTop:"2%"}}>
        <div className="container" data-aos="fade-up">
          <h2>Branches</h2>

          <article
            style={{
              width: "100%",
              padding: "10px",
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            <aside className="coursesForr">
              {/* cards */}
              {allBranches.map((data) => (
                <Card
                  sx={{
                    maxWidth: 345,
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 300,
                    minHeight: 300,
                  }}
                >
                  <Box sx={{ flexBasis: "30%" }}>
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ backgroundColor: "#F97B22" }}
                          aria-label="recipe"
                        >
                          <BusinessOutlinedIcon />
                        </Avatar>
                      }
                      title={
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "600",
                            fontSize: "21px",
                            fontStyle: "normal",
                            color: "#F97B22",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "20px",
                              textTransform: "capitalize",
                              fontStyle: "normal",
                              color: "black",
                            }}
                          >
                            {data.city.name} -
                          </span>{" "}
                          {data.branch}
                        </Typography>
                      }
                    />
                  </Box>
                  <Box sx={{ flexBasis: "10%" }}>
                    <Box>
                      <Chip
                        label={data.state.name}
                        sx={{
                          backgroundColor: "#efefef",
                          color: "black",
                          fontStyle: "italic",
                          fontWeight: "600",
                          float: "right",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ flexBasis: "40%" }}>
                    <CardContent>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ marginTop: "15px" }}
                      >
                        {data.address}
                      </Typography>
                    </CardContent>
                  </Box>
                  <Box sx={{ flexBasis: "10%" }}>
                    <CardActions disableSpacing>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                        
                        <Tooltip title="Call Now" placement="bottom">
                        <IconButton>
                        <CallIcon style={{ color: "#F97B22" }} /> 
                        </IconButton>
                        </Tooltip>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "600", fontSize: "18px" }}
                          >
                            <a href={`tel:${data.contact_number}`} target="_blank" style={{color:"black"}}>
                            {data.contact_number}
                            </a>
                            
                          </Typography>
                        </div>

                        <Tooltip title="View Location">
                          <Link to={data.map_link} target="_blank">
                            <IconButton>
                              <LocationOnIcon style={{ color: "#19A7CE" }} />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </Box>
                    </CardActions>
                  </Box>
                </Card>
              ))}
            </aside>
          </article>
        </div>
      </section>
    </>
  );
};

export default BranchesList;

{
  /* <aside>
<h3>Why take this course?</h3>
<ul>
  <li>
    <i className="bi bi-check2-all"></i>
    This course is blended with current industry requirements in
    various fields where even a fresher can start working on
    projects directly.{" "}
  </li>
  <li>
    <i className="bi bi-check2-all"></i>
    This package gives desired knowledge on various job roles and
    builds confidence in freshers to immediately transform in to
    an employed class after completing the course with us.
  </li>
  <li>
    <i className="bi bi-check2-all"></i>
    Your performance will increase by activities like
    Presentations, Assignments, Mock Interviews & Mock tests which
    will run regularly.{" "}
  </li>
  <li>
    <i className="bi bi-check2-all"></i>
    100% JOB ASSISTANCE after completion of course to make your
    Profile reach to Hundreds of Recruiters in our network.
  </li>
</ul>
</aside> */
}
