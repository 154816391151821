import { createContext, useState } from "react";

export const PostContext = createContext();

export const PostContextProvider = ({ children }) => {
    let [enquireData, setEnquireData] = useState(null);


  return (
    <PostContext.Provider
      value={{
        enquireData,setEnquireData
      }}
    >
      {children}
    </PostContext.Provider>
  );
};