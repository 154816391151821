import React, { useState } from "react";
import CountUp from "react-countup";

const Facts = () => {
  let [count, setCount] = useState(false);
  let scrolled = () => {
    if (window.scrollY > 160) {
      setCount(true);
    } else {
      setCount(true);
    }
  };
  window.addEventListener("scroll", scrolled);
  return (
    <>
      <section id="counts" className="counts">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="count-box">
                <i className="fa-solid fa-trophy"></i>
                <span className="purecounter">
                  {count ? (
                    <CountUp start={100} end={1} duration={2.5} prefix="No." />
                  ) : (
                    ""
                  )}
                </span>
                <p>Largest Training Institute in the India</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
              <div className="count-box">
                <i className="fa-solid fa-users"></i>
                <span className="purecounter">
                  {count ? (
                    <CountUp start={1} end={380} duration={2.5} suffix="k" />
                  ) : (
                    ""
                  )}
                </span>
                <p>3.8 Lakh+ Students Have Been Placed & Still Counting</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="fa-solid fa-landmark"></i>
                <span className="purecounter">
                  {count ? (
                    <CountUp start={100} end={3970} duration={2.5} suffix="+" />
                  ) : (
                    ""
                  )}
                </span>
                <p>Companies Hire from us & the list is Still Growing</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="fa-solid fa-handshake"></i>
                <span className="purecounter">
                  {count ? (
                    <CountUp start={1} end={19} duration={3} suffix="+" />
                  ) : (
                    ""
                  )}
                </span>
                <p>19+ Companies Conduct Interviews Every Working Day</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Facts;
