import React from "react";

const About = () => {
  return (
    <>
      <section id="about" className="about section-bg">
        <div className="container" data-aos="fade-up">
          <h2>About Us</h2>
          <div className="row no-gutters">
            <div className="content col-xl-5 d-flex align-items-stretch">
              <div className="content">
                <h3>India’s first organization as finishing school</h3>
                <p>
                  Exclusively for professional courses,management,non technical,
                  non engineering
                </p>
                <a href="#about" className="about-btn">
                  <span>About us</span> <i className="bx bx-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="col-xl-7 d-flex align-items-stretch">
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">
                  <div
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="bx bx-receipt"></i>
                    <h4>Who we are</h4>
                    <p>
                      ProSpiders,A wing of QSpiders which is a best-in-class
                      learning solutions organization headquartered in India’s
                      IT capital, Bangalore.
                    </p>
                  </div>
                  <div
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i className="bx bx-cube-alt"></i>
                    <h4>What we Do</h4>
                    <p>
                      We offer a wide range of courses based on the industry
                      demand. A “finishing school” in many ways, the institute
                      provides young job aspirants the perfect launch-pad to
                      build a rewarding career in the growing market sector.
                    </p>
                  </div>
                  <div
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i className="bx bx-images"></i>
                    <h4>What to Expect</h4>
                    <p>
                      At ProSpiders, we ensure training is imparted by
                      specialists with proven subject matter expertise. These
                      are job oriented courses which will enable the students by
                      elevating them to today’s industry/job market demand.
                    </p>
                  </div>
                  <div
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i className="bi bi-award"></i>
                    <h4>Our Records</h4>
                    <p>
                      We have placed over lakhs of job-seekers in various firms
                      across India over the years with an aim to place thousands
                      more!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
