import React from "react";
import About from "../about/About";
import Facts from "../about/Facts";
import Batches from "../batches/Batches";
import Courses from "../courses/Courses";
import Enquiry from "../enquiry/Enquiry";

const Main = () => {
  return (
    <main id="main">
      <Facts />
      <About />
      <Courses />
      <Batches />
      <Enquiry />
    </main>
  );
};

export default Main;
