import React, { useEffect, useState } from "react";
import AxiosInstance from "../../api/AxiosInstance";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Contact = () => {
  const [branches, SetBranches] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const branchList = await AxiosInstance.get(`spiders/branch/`);

      let finalBranchList = branchList.data.results;
      let Fdata = finalBranchList;
      SetBranches(Fdata);
    };
    fetchData();
  }, []);

  const infoBoxStyle = {
    height: "260px",
    overflow: "auto",
  };

  if (window.innerWidth <= 908) {
    infoBoxStyle.height = "450px";
  }

  if (window.innerWidth <= 620) {
    infoBoxStyle.height = "360px";
  }

  const infoBoxStyle2 = {
    height: "220px",
    overflow: "auto",
    padding: "10px 0 30px 0",
    marginBottom: "",
  };

  if (window.innerWidth <= 620) {
    infoBoxStyle2.height = "320px";
    infoBoxStyle2.marginBottom = "10px";
  }

  return (
    <>
      <div className="col-lg-6">
        <div className="row">
          <div className="col-md-12">
            <div className="info-box" style={infoBoxStyle}>
              <h5 style={{ textAlign: "center", fontWeight: "600" }}>
                Our Address
              </h5>
              {branches.map(data => (
                <>
                  <span style={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip title="view location" placement="left-start">
                      <Link to={data.map_link} target="_blank">
                        <IconButton>
                          <LocationOnIcon style={{ color: "#19A7CE" }} />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <h3 style={{ color: "#fd911d" }}>{data.branch}</h3>
                  </span>

                  <p className="p-2px">{data.address}</p>
                </>
              ))}
              {/* <Link to="/allBranchList">
              <div style={{float:"right",paddingRight:"20px",marginTop:"10px"}}> 
           <button type='button' className="btnStyleR" style={{border:"1px solid white",borderRadius:"5px",color:"white",padding:"5px",backgroundColor:"#f8931f"}}> view more </button>
           </div>
          </Link> */}
            </div>
          </div>
          <div className="col-md-6">
            <a href="mailto:contact@prospiders.com" target="_blank">
              <div className="info-box mt-4">
                <i className="bx bx-envelope" style={{ color: "crimson" }}></i>
                <h3>Email Us</h3>
                <p>
                  <br />
                  enquiry@qspiders.com
                </p>
              </div>
            </a>
          </div>
          <div className="col-md-6">
            <a href="tel">
              <div className="info-box mt-4" style={infoBoxStyle2}>
                <i
                  className="bi bi-whatsapp"
                  style={{ color: "yellowgreen" }}
                ></i>
                <h3> Call | Whatsapp</h3>
                <p>
                  {branches.map(data1 => (
                    <>
                      <span style={{ color: "#fd911d", fontWeight: "600" }}>
                        {data1.branch}{" "}
                      </span>{" "}
                      <br />
                      <a
                        href={`tel:${data1.contact_number}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#454545", fontWeight: "600" }}
                      >
                        {data1.contact_number}
                      </a>
                      <br />
                    </>
                  ))}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
