import React, { useContext } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Axios from "../../api/AxiosInstance";
import { PostContext } from "../ContextApi";

const Form = () => {
  const { enquireData, setEnquireData } = useContext(PostContext);
  const[statebtn,setStateBtn]=useState(false)

  let [state, setState] = useState({
    your_name: "",
    mobile_number: "",
    your_email: "",
    your_message: "",
    mode_of_class: "",
  });
  let { your_name, mobile_number, your_email, your_message, mode_of_class } =
    state;
  let handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    setStateBtn(true)
    let formData = new FormData();
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  
    formData.append("your_name", your_name);
    formData.append("mobile_number", "+91" + mobile_number);
    formData.append("your_email", your_email);
    formData.append("your_message", your_message);
    formData.append("mode_of_class", mode_of_class);
    if (enquireData !== null) {
      formData.append("classes", enquireData.id);
    }

    try {
      await Axios.post(`spiders/enquiry-form/`, formData, config)
        .then((response) => {
          setStateBtn(false)
          toast.success("Registered Successfully");
          setTimeout(() => {
          window.location.assign("/");
          },500);
        })
        .catch((err) => {
          setStateBtn(false)
          toast.error("Something Went Wrong Please Try Again");
        });
    } catch (error) {
      setStateBtn(false)
      toast.error("Something Went Wrong Please Try Again");
    }
  };

  return (
    <div className="col-lg-6">
      <form action="#" className="EnqForm" id="regForm" onSubmit={handleSubmit}>
        <h2
          style={{
            color: "#f86b1f",
            textAlign: "center",
            marginBottom: "5%",
            fontWeight: "bold",
          }}
        >
          Register Here
        </h2>
        <div className="row">
          <div className="col form-group">
            <input
              type="text"
              name="your_name"
              className="form-control"
              value={your_name}
              id="your_name"
              placeholder="Your Name"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col form-group">
            <input
              type="tel"
              className="form-control"
              name="mobile_number"
              value={mobile_number}
              maxLength="10"
              id="mobile_number"
              placeholder="Your Phone Number"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            name="your_email"
            value={your_email}
            id="your_email"
            placeholder="Your Email"
            onChange={handleChange}
            required
          />
        </div>
        <div
          className="form-group"
          id="mode_of_className"
          value={mode_of_class}
          onChange={handleChange}
        >
          <label htmlFor="mode">
            <b>Mode of Class: </b>
          </label>
          &nbsp;&nbsp;
          <input
            type="radio"
            name="mode_of_class"
            id="mode"
            value="1"
            required
          />{" "}
          Online &nbsp;&nbsp;
          <input type="radio" name="mode_of_class" id="mode" value="2" />{" "}
          Offline
        </div>
        <div className="form-group">
          <textarea
            value={your_message}
            onChange={handleChange}
            className="form-control"
            name="your_message"
            rows="5"
            placeholder="Message"
          ></textarea>
        </div>
        <div className="text-center">
          <button type="submit" disabled={statebtn === true ? true :false} style={{cursor:statebtn ===true ? "not-allowed":"pointer"}}>{statebtn === true ? "Sending Enquiry":"Register Now"}</button>
        </div>
      </form>
    </div>
  );
};

export default Form;
