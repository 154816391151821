import React from "react";
import Styles from "./page.module.css";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import AddTaskIcon from "@mui/icons-material/AddTask";
import SettingsIcon from "@mui/icons-material/Settings";
import GradeIcon from "@mui/icons-material/Grade";

const TrnModules = () => {
  return (
    <section id={Styles.CardMainBlock}>
      <div className={Styles.containerHeading}>
        <h2 className={Styles.headingBlock}>Training Modules</h2>
        <span className={Styles.borderBottom}></span>
      </div>
      <article>
        <div className={Styles.card} id={Styles.card1}>
          <h1>
            <b>
              <LaptopChromebookIcon />
            </b>
            &nbsp; Technical
          </h1>
          <ul>
            <li>SQL</li>
            <li>Hardware</li>
            <li>Networking</li>
            <li>Mail Servers</li>
            <li>Excel, Word,PPT</li>
            <li>OS Windows-Linux</li>
          </ul>
        </div>
        <div className={Styles.card}>
          <h1>
            <b>
              <SettingsIcon />
            </b>
            &nbsp;Non Technical
          </h1>
          <ul>
            <li>Spoken English</li>
            <li>Email Etiquette</li>
            <li>Behavioral Grooming</li>
            <li>Personality Development</li>
          </ul>
        </div>
        <div className={Styles.card} id={Styles.card3}>
          <h1>
            <b>
              <AddTaskIcon />
            </b>{" "}
            &nbsp; Value Adds
          </h1>
          <ul>
            <li>Grooming</li>
            <li>Mock Interviews</li>
            <li>Assignment & Test</li>
            <li>Presentation Activities</li>
          </ul>
        </div>
      </article>

      <div className={Styles.cardSecond}>
        <h3
          style={{ color: "#444", marginBottom: "20px", fontWeight: "800" }}
          className={Styles.subHead1}
        >
          Apart from the above mentioned course you can also enroll for
          specialization courses
        </h3>
        <h1 className={Styles.subHead}>
          <b>
            <GradeIcon />
          </b>
          &nbsp;Specialized Courses
        </h1>
        <ul>
          <li>Process Analyst</li>
          <li>Digital Marketing Analyst</li>
          <li>Cloud Analyst</li>
          <li>HR & Recruitment</li>
        </ul>
      </div>
    </section>
  );
};

export default TrnModules;
