import React from "react";
import Header from "../navbar/Header";
import Banner from "../banner/Banner";
import Main from "../main/Main";
import Footer from "../footer/Footer";
import BackToTop from "../bTop/BackToTop";


const LandingPage = () => {
  return (
    <div>
      <Header/>
      <Banner/>
      <Main/>
      <Footer/>
      <BackToTop/>
    </div>
  );
};

export default LandingPage;
