import React, { useState } from "react";
import logo from "./../../assets/img/Logo.png";

const Header = () => {
  const [isShown, setIsShown] = useState(true);
  let navLinks = document.querySelectorAll(".scrollto");
  navLinks.forEach(e => {
    if (e.href === window.location.href) {
      e.setAttribute("aria-current", "page");
    }
  });
  let handleClick = () => {
    setIsShown(!isShown);
    document.querySelectorAll(".nav-link").forEach(e => {
      e.addEventListener("click", e => {
        e.target.parentElement.parentElement.parentElement.style.display =
          "none";
      });
    });
    if (isShown == true) {
      document.querySelector(".hamMenuBlock").style.display = "block";
    } else {
      document.querySelector(".hamMenuBlock").style.display = "none";
    }
  };
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <h1 className="logo me-auto">
            <a href="#">
              <span>Pro</span>Spiders
            </a>
          </h1>
          {/* <a href="index.html" className="logo me-auto">
      <img src={logo} alt=""/></a> */}

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <a className="nav-link scrollto" href="#">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#tabs">
                  Courses
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="/allBatches">
                  Batches
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
            <i
              className="bi bi-list mobile-nav-toggle mobileMenu"
              onClick={handleClick}
            ></i>
          </nav>
          <div className="hamMenuBlock">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#tabs">
                  Courses
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="/allBatches">
                  Batches
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <a href="#regForm" className="get-started-btn scrollto">
            Register Now
          </a>
        </div>
      </header>
    </>
  );
};

export default Header;
