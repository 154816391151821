import React from 'react'
import OnlineNavbar from '../navbar/OnlineNavbar'
import BranchesList from './BranchesList'

const AllBranchList = () => {
  return (
    <div>
      <OnlineNavbar/>
      <BranchesList/>
    </div>
  )
}

export default AllBranchList
